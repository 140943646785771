import './bootstrap'
import '../css/app.css'
import VueApexCharts from 'vue3-apexcharts'
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import "vue3-perfect-scrollbar/style.css";
import { i18nVue } from 'laravel-vue-i18n'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import '../css/custom.css'
dayjs.extend(relativeTime)

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Codeshaper'

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(VueApexCharts)
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(Antd)
      .use(i18nVue, {
        resolve: async (lang) => {
          const langs = import.meta.glob('../../lang/*.json')
          return await langs[`../../lang/${lang}.json`]()
        }
      })
    app.config.globalProperties.$filters = {
      currency(value) {
        const baseCurrency = props.initialPage.props.auth?.baseCurrency?.symbol || '$';
        const formattedValue = new Intl.NumberFormat('en-US').format(value);
        return `${baseCurrency}${formattedValue}`;
      },
      number(value) {
        const formattedValue = new Intl.NumberFormat('en-US').format(Math.abs(value))
        return formattedValue
      },
      pluralDetector(value, pluralText) {
        try {
          let numberValue = parseInt(value)
          if (numberValue == 0) {
            return pluralText
          } else if (numberValue > 1) {
            return pluralText
          }
          return ''
        } catch {
          return ''
        }
      }
    }
    app.mount(el)
  },
  progress: {
    color: '#4B5563'
  }
})
